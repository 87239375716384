<template>
    <div class="fans_box">
        <div class="fans_header">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
                <el-breadcrumb-item class="first" style="cursor: pointer;"><span @click="goBack" >工作台</span></el-breadcrumb-item>
                <el-breadcrumb-item class="second">粉丝排行榜</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="go_back" @click="goBack">返回</el-button>
        </div>
        <div class="fans_list_box">
            <p class="fans_title">粉丝排行榜</p>
            <div class="fans_table">
                <template>
                    <el-table :data="fansRankList" height="760px" style="width: 100%;"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', }"
                        :cell-style="{ fontSize: '14px', color: ' #252632' }">
                        <el-table-column label="排名" width="100" align="center">
                            <template slot="default" slot-scope="{ $index }">
                                <div>
                                    <img v-if="$index === 0" src="../../../assets/img/work/one.png" alt="">
                                    <img v-else-if="$index === 1" src="../../../assets/img/work/two.png" alt="">
                                    <img v-else-if="$index === 2" src="../../../assets/img/work/three.png" alt="">
                                    <span v-else>{{ $index + 1 }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="头像/昵称">
                            <template slot-scope="scope">
                                <div class="image_box">
                                    <div class="image">
                                        <img :src="scope.row.avatar" alt="">
                                    </div>
                                    <span class="nickName">{{ scope.row.nickname }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="发布内容/昨日新增">
                            <template slot-scope="scope">
                                <div>{{ getNumber(scope.row.aweme_count) }}/{{ getNumber(scope.row.aweme_count_yesterday) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="粉丝数/昨日新增">
                            <template slot-scope="scope">
                                <div>{{ getNumber(scope.row.fans_num) }}/{{ getNumber(scope.row.fans_num_yesterday) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="30天获赞/昨日新增">
                            <template slot-scope="scope">
                                <div>{{ getNumber(scope.row.digg_count_30) }}/{{ getNumber(scope.row.digg_count_yesterday)
                                }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fansRankList: []
        }
    },
    mounted() {
        this.getFansList()
    },
    methods: {
        getFansList() {
            this.$httpStudent.axiosGet(this.$api.account_fansRanking, res => {
                if (res.code == 200) {
                    this.fansRankList = res.data;
                    

                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        goBack() {
            this.$router.push("/student/works/index")
        },
        getNumber(num) {
            if (num >= 100000000) {
                return (num / 10000 / 10000).toFixed(2) + "亿"
            } else if (num >= 10000) {
                return (num / 10000).toFixed(2) + "万"
            } else {
                return num
            }

        }
    }
}
</script>
<style lang="scss" scoped> p {
     padding: 0;
     margin: 0;
 }

 .fans_box {
     width: 100%;
     height: calc(100% - 40px);
     .fans_header {
         width: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;
         height: 54px;

         .go_back {
             width: 80px;
             height: 32px;
             background: #574DED;
             color: #ffffff;
             border-radius: 4px 4px 4px 4px;
             display: flex;
             align-items: center;
             justify-content: center;
         }
     }

     .fans_list_box {


         width: 100%;
      
         background: #FFFFFF;
         border-radius: 4px 4px 4px 4px;
         padding: 20px;
         box-sizing: border-box;
         padding-bottom: 50px;

         .fans_title {
             font-family: PingFang SC, PingFang SC;
             font-weight: 400;
             font-size: 24px;
             color: #000000;
             margin-bottom: 20px;
         }

         .fans_table {}
     }
 }

 .image_box {
     display: flex;
     align-items: center;

     .image {
         width: 48px;
         height: 48px;
         border-radius: 100%;

         img {
             width: 100%;
             height: 100%;
             border-radius: 100%;
         }


     }

     .nickName {
         margin-left: 10px;
         display: inline-block;
     }
 }</style>